import App from './App';
import ReactDOM from 'react-dom';

ReactDOM.render(
 <App />, 
 document.getElementById('root')
);

// import App from './App';

// const ReactDOMClient = require('react-dom/client')

// const container = document.getElementById('root');

// // Create *and* render a root with hydration.
// ReactDOMClient.hydrateRoot(container, <App />);
// // Unlike with createRoot, you don't need a separate root.render() call here