import { FunctionComponent } from 'react';
import Slider from '../components/slider';

const TheyTrustUsPage: FunctionComponent = () => {
    return (
        <div className='container ' style={{ marginTop: '25px' }}>
            <div className='row lighten-3 white-text' style={{ background: "rgba(0, 0, 0, 0.5)" }}  >
                <h3 className='center' style={{margin:'5px'}}>
                <i className="blue-text material-icons medium" style={{verticalAlign: '-10px', paddingLeft: '10px', paddingRight: '50px' }}>cloud</i>
                <span>They trust us</span>
                </h3>
            </div>
            <Slider />

        </div>
    );
}

export default TheyTrustUsPage;