
import React, { Component } from 'react';
import M from "materialize-css";
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import './sidenav.css';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;
export type link = {
  id: number,
  path: string,
  color: string,
  icon: string,
  key: string
};

export type SidenavProps = {
  links: link[],
  toolbarEnabled?: boolean,
  classes?: string,
  styleDict?: object,
  buttonClass?: string
};


class Sidenav extends Component<SidenavProps> {
  private input = React.createRef<HTMLDivElement>();

  componentDidMount() {
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });
    const options = {
    };
    document.addEventListener('DOMContentLoaded', function () {
      var elems = document.querySelectorAll('.sidenav');
      M.Sidenav.init(elems, options);
    });
  }

  render() {
    return (
      <div>
        <Link to="#" data-target="slide-out" className="sidenav-trigger show-on-large" style={{ marginTop: '5px' }}>
          <i className="material-icons">menu</i>
        </Link>

        <ul id="slide-out" className="sidenav " style={{ background: "rgba(0, 0, 0, 0.5)" }} >

          <li className="white-text " style={{ marginTop: '100px' }}>
            <Link to="/whatwedo" className='sidenav-close white-text'>
              <i className="material-icons green-text text-darken-5">cloud</i>What we do
            </Link>
          </li>
          <li className="white" style={{ marginLeft: '25px' }}><div className="divider" ></div></li>
          <li className="white-text" style={{ marginLeft: '25px' }}>
            <Link to="/whoweare" className='sidenav-close white-text'>
              <i className="material-icons purple-text text-darken-5">cloud</i>Who we are
            </Link>
          </li>
          <li className="white" style={{ marginLeft: '50px' }}><div className="divider"></div></li>
          <li className="white-text " style={{ marginLeft: '50px' }}>
            <Link to="/theytrustus" className='white-text sidenav-close'>
              <i className="material-icons blue-text text-darken-5">cloud</i>They trust us
            </Link>
          </li>
          <li className="white" style={{ marginLeft: '75px' }}><div className="divider"></div></li>
          <li className="white-text " style={{ marginLeft: '75px' }}>
            <Link to="/demo" className='white-text sidenav-close'>
              <i className="material-icons red-text text-darken-5">cloud</i>Demos
            </Link>
          </li>
          <li className="white" style={{ marginBottom: '100px', marginLeft: '100px' }}><div className="divider"></div>
          </li>
          <li className="white-text">
            <a href="mailto:contact@imprevicible.com" className='white-text'>
              <i className="material-icons white-text text-darken-5">email</i>
              contact@imprevicible.com
            </a>
          </li>
          <li>
            <MapContainer style={{ height: "250px" }} center={[48.8534951, 2.3483915]} zoom={4} zoomControl={false} scrollWheelZoom={true}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={[48.925, 2.03711]}>
                <Popup>
                  <img alt="map" className="rectangle" src={logo} style={{ width: '100px' }} /><br /> Poissy, France.
                </Popup>
              </Marker>
            </MapContainer>
          </li>
        </ul>
      </div>
    )
  }
}

export default Sidenav;