
import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import M from "materialize-css";
import './demo.css';
import NlpService, { NlpResults}  from '../services/nlp-service';


export type DemoProps = {
    document?: any
  }
export type  Form = {
    text: string|null,
    showProgressBar: boolean,
    showInputTab: string,
    showResultTab: string,
    showAnnotations: boolean,
    results: NlpResults|null,
    taggedText:any[],
    lang:string
}
const TEXTSIZE = 200;


const DemoForm: FunctionComponent<DemoProps> = () => {
    const textareaRef = useRef<any | null>();
    const progressRef = useRef<any | null>();
    const tabsRef = useRef<any|null>();
    const dropdownRef = useRef<any>();
    // const history = useHistory();

  
    const [form, setForm] = useState<Form>({
      text: null,
      showProgressBar: false,
      showInputTab: 'active',
      showResultTab: '',
      results: null,
      showAnnotations: false,
      taggedText:[],
      lang:'en'
    });

    const [dataError, setDataError] = useState<boolean>(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        process();
    }
    const getTaggedText = (results:any) => {
        let spans:any = [];          
        let beg:any = {};
        let end:any = {};
        if (results){
            results.Result.map((entity:any)=>{
                beg[entity.coordinates[0]] = {tag: entity.tag, id:entity.id};
                end[entity.coordinates[1]-1] = '';
                return null;
            });
        }
        if (form.text){
            let span_text:string = '';
            let inprogress:boolean = false
            let lab:string = '';
            let entityId:string = '';
            for (var char=0;char<form.text.length;char+=1){
                if (char in beg){
                    if (span_text !== ''){
                        if (inprogress){
                            spans.push({'text':span_text, 'type':lab, id: entityId});
                        } else{
                            spans.push({'text':span_text, 'type':'O', id: Math.random().toString()});
                        }
                        span_text = ''
                    }
                    lab = beg[char]['tag'];
                    entityId = beg[char]['id'];
                    span_text += form.text[char]
                } else if (char in end){
                    inprogress = false;
                    span_text += form.text[char]
                    spans.push({'text':span_text, 'type':lab, id: entityId});
                    span_text = '';
                } else {
                    span_text += form.text[char]
                }
            }
            if (span_text !== ''){
                if (inprogress){
                    spans.push({'text':span_text, 'type':lab, id: entityId});
                } else{
                    spans.push({'text':span_text, 'type':'O', id: Math.random().toString()});
                }
            }
        }
        return spans;
    }
    const process = () => {
        // delete previous results
        setForm({...form, ...{results: null}});
        // validate size

        if (form.text === null || (form.text && form.text.length > TEXTSIZE)){
            alert('Please check the size of the input is not over'+TEXTSIZE.toString());
            return;
        }
        var error:boolean = false;
        NlpService.get(form.text)
            .then((data:NlpResults)=>{
                if (data === null){
                    error = true;
                }
                else{
                    const spans = getTaggedText(data);
                    setForm({...form, ...{showProgressBar: false, results: data, taggedText: spans}});
                }
                setDataError(error);
        })
        
        setForm({...form, ...{showProgressBar: true, showInputTab: '', showResultTab: 'text-green active'}});
        var instance = M.Tabs.getInstance(tabsRef.current);
        instance.select('results');
    }
    const handleInputChange = (e:React.FormEvent<HTMLTextAreaElement>) =>{
        setForm({...form, ...{text: (e.target as HTMLTextAreaElement).value}});
    }
    const toggleShowAnnotation = () =>{
        if (form.showAnnotations){
            setForm({...form, ...{showAnnotations: false}});
        } else {
            setForm({...form, ...{showAnnotations: true}});
        }
        setTimeout(() => {
            initTooltips();
          }, 0)
    }
    useEffect(() => {
        if (textareaRef && textareaRef.current) {
                M.textareaAutoResize(textareaRef.current);
                M.CharacterCounter.init(textareaRef.current);
        }
        if (tabsRef && tabsRef.current){
            M.Tabs.init(tabsRef.current, {});
        }
        M.Dropdown.init(dropdownRef.current,{})
    },[]);
    const initTooltips =()=>{
        var elems = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(elems, {});
        return null;
    }
    const setLanguage = (lang:string) => {
        setForm({...form, ...{lang: lang}});
    }

    return (
        <div className='container' style={{marginTop:'25px'}}>
            <div className='row lighten-3 white-text' style={{ background: "rgba(0, 0, 0, 0.5)" }}  >
                <h3 className='center' style={{margin:'5px'}}>
                <i className="red-text material-icons medium" style={{verticalAlign: '-10px', paddingLeft: '10px', paddingRight: '50px' }}>cloud</i>
                <span>Demos</span>
                </h3>
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>

                <div className='row'>
                    <ul ref={tabsRef} className="tabs tabs-fixed-width transparent">
                        <li className="tab col s3 "><a className={form.showInputTab} href="#input">Input</a></li>
                        <li className="tab col s3 "><a className={form.showResultTab} href="#results">Results</a></li>
                    </ul>
                    <div className="row">
                            <input type="checkbox" checked={true} className='filled-in' readOnly/>

                            {/* <input type="checkbox"  >*/}
                            <span ref={dropdownRef} className='hoverable dropdown-trigger' data-target='dropdown-lang'>Lang.:{form.lang}</span> 
                            <ul id='dropdown-lang' className="dropdown-content">
                                <li><a href="#!" onClick={()=>{setLanguage('fr')}}>French</a></li>
                                <li><a href="#!" onClick={()=>{setLanguage('en')}}>English</a></li>
                            </ul>
                    </div>

                    <div id="input" style={{paddingLeft:'0px', paddingRight:'0px'}} className='row input-field'>
                        <textarea ref={textareaRef} id="textarea" className="materialize-textarea white"
                            style={{minHeight:'400px', overflow:'scroll', maxHeight:'600px'}} data-length={TEXTSIZE}
                             onChange={e => handleInputChange(e)}
                            />
                        <label htmlFor="textarea">Insert your text here</label>
                        <input type="submit" className='btn' value="RUN" />
                    </div>
                    <div id="results" style={{paddingLeft:'0px', paddingRight:'0px'}} className='row input-field'>
                        {form.showProgressBar && !dataError &&
                            <div ref={progressRef} className="progress">
                                <div className="indeterminate"></div>
                            </div>
                        }
                        {dataError &&
                            <div className='white red-text text-darken-5 flow-text'>
                                <i className="material-icons">error_outline</i>
                                Something went wrong... Please try again later.
                            </div>
                        }
                        { form.results && 
                            <div className='white s12'>
                                <h4>Entities</h4>
                                { form.results.Result.map( (entity:any) => 
                                    <div key={entity.id}>
                                        <span>{entity.entity + "=" + entity.tag +"("+entity.coordinates+")"}</span>
                                        <br/>
                                    </div>
                                )}
                            </div>
                        }
                        {!dataError &&
                        <div className="s12"><a href="#!" className="btn" onClick={(e)=>{toggleShowAnnotation()}}>highlight text</a></div>
                        }
                        { form.results && form.showAnnotations && form.taggedText !== [] &&
                            <div className='white s12'>
                                <h4>Entities</h4>
                                { form.taggedText.map(span => 
                                    span.type === 'O'?
                                        <span key={span.id} className="white black-text">{span.text}</span> :
                                        <span key={span.id} className="tooltipped blue white-text hoverable" data-position="top" data-tooltip={span.type}>{span.text}</span>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </form>
        </div>
    );
};

export default DemoForm;