
const URL= process.env.REACT_APP_NLPSERVICE_URL;

export default class NlpService {

  static get(text:string): Promise<NlpResults> {
    return fetch(URL+'/nlp', {
      method: "POST",
      body: JSON.stringify({text: text}),
      headers: { 'Content-Type': 'application/json'}
    })
    .then((response) => {
      if (response.status !== 200){
        return null;
      }
      return response.json();})
    .catch(error => this.handleError(error));

  }


  static isEmpty(data: Object): boolean {
    return Object.keys(data).length === 0;
  }

  static handleError(error: Error): void {
    console.error(error);
  }
}

export type NlpResults = {
  Result: Entity[]
}
export type Entity = {
  entity: string,
  id: string,
  tag: string,
  coordinates: number[]
}