


import React, { Component } from 'react';
import M from "materialize-css";
import twoos from '../assets/images/2os-paris.jpeg';
import bizgive from '../assets/images/bizgive-london.jpeg';
import adarga from '../assets/images/adarga-london.jpeg';
import fortia from '../assets/images/fortia-paris.jpeg';
import cd2i from '../assets/images/cd2i-toulouse.jpeg';


export type SliderProps = {
  document?: any
}


class Slider extends Component<SliderProps> {
    private input = React.createRef<HTMLDivElement>();
    
    componentDidMount() {
        if (this.input.current){
            M.Slider.init(this.input.current, {});
        }
    }
    pause(input:any){
        if (input.current){
            const instance = M.Slider.getInstance(input.current);
            instance.pause();
        }
    }
    play(input:any){
        if (input.current){
            const instance = M.Slider.getInstance(input.current);
            instance.next();
        }
    }
    render(){
      return(
        <div ref={this.input} className="slider black" data-indicators="true" style={{height:'600px', overflowY: 'scroll', border: '5px solid black'}}>
            {/* onMouseEnter={() => this.pause(this.input)} onMouseLeave={() => this.play(this.input)}  */}
            <ul className="slides black">
                <li className='container'>
                    <div className='row' style={{height:'100%', overflowY: 'scroll'}}>
                        <div className='col s6'>
                            <img alt="Fortia" style={{ maxHeight: '350px'}} src={fortia}/>
                        </div>
                        <div className='col s6' >
                            <div className='grey white-text center'>
                                <h5 style={{margin:'1px',padding:'1px'}}>Developing Artificial Intelligence solutions for the Finance sector</h5>
                            </div>
                            <div className='white' style={{marginTop:'10px', padding:'5px'}} >
                                <i>Really cool AI projects with <a href="https://fortia.fr"><b>Fortia Financial Solutions</b>, Paris</a>, including:</i>
                            </div>
                            <div className='white'  >
                                <i className=' material-icons' style={{margin: '2px', border: 'solid 1px', verticalAlign: '-6px'}}>show_chart</i><b>Time Series Analysis</b> applied to Net Asset value conformity,
                            </div>
                            <div className=' white' >
                                <i className='material-icons'  style={{margin: '2px', border: 'solid 1px', verticalAlign: '-6px'}}>format_align_left</i><b>Deep Information Extraction</b> on a variety of Financial documents e.g. Prospectuses, Annual reports, KIIDs, Offering Memorandums
                            </div>
                            <div className='white'>
                                <i className='material-icons'  style={{margin: '2px', border: 'solid 1px', verticalAlign: '-6px'}}>import_contacts</i><b>Text generation</b> applied to prospectus and financial reports generation from structured data
                            </div>
                        </div>
                    </div>
                </li>
                {/* <li className='container'>
                <div className='row ' style={{height:'100%', overflowY: 'scroll'}}>
                        <div className='col s6 '>
                            <img alt="2OS"  style={{maxHeight:'350px'}}  src={twoos}/>
                        </div>
                        <div className='col s6 top' >
                            <div className='grey white-text center'>
                                <h5 style={{margin:'1px',padding:'1px'}}>Cloud Infratructure setup & Deployment of a SAAS product</h5>
                            </div>
                            <div className='white' style={{marginTop:'10px', padding:'5px'}} >
                                <i>Design and Buildup of the cloud infrastructure of a multitenanted NoCode platform on Azure for <a href="https://fortia.fr"><b>2OS at Fortia</b>, Paris</a>, including:</i>
                            </div>
                            <div className='white'  >
                                <i className=' material-icons' style={{margin: '2px', border: 'solid 1px', verticalAlign: '-6px'}}>security</i><b>Platform Security</b> Isolating resources behind VPNs, deploying Firewalls, and state of the art user authentication.
                            </div>
                            <div className=' white' >
                                <i className='material-icons'  style={{margin: '2px', border: 'solid 1px', verticalAlign: '-6px'}}>cloud_done</i><b>Deployment automation</b> Continuous integration, continuous deployment and Infrastructure as Code.
                            </div>
                            <div className='white'>
                                <i className='material-icons'  style={{margin: '2px', border: 'solid 1px', verticalAlign: '-6px'}}>dashboard</i><b>Application Monitoring and Logging</b> Performance and Application alerts/dashboards, regular data backups.
                            </div>
                        </div>
                    </div>
                </li> */}
                <li className='container'>
                    <div className='row' style={{height:'100%', overflowY: 'scroll'}}>
                        <div className='col s6'>
                            <img alt="Adarga"  style={{maxHeight:'350px'}}  src={adarga}/>
                        </div>
                        <div className='col s6' >
                            <div className='grey white-text center'>
                                <h5 style={{margin:'1px',padding:'1px'}}>Developing Artificial Intelligence solutions for the Defence sector</h5>
                            </div>
                            <div className='white' style={{marginTop:'10px', padding:'5px'}} >
                                <i>Really cool AI projects with <a href="https://adarga.ai"><b>Adarga Ltd.</b>, London</a>, including:</i>
                            </div>
                            <div className='white'  >
                                <i className=' material-icons' style={{margin: '2px', border: 'solid 1px', verticalAlign: '-6px'}}>nature_people</i><b>Information Extraction</b> applied to the detection of mentions of Organizations, Places and People and their relationships,
                            </div>
                            <div className=' white' >
                                <i className='material-icons'  style={{margin: '2px', border: 'solid 1px', verticalAlign: '-6px'}}>person_pin_circle</i><b>Entity Disambiguation</b> mapping data automatically extracted from news and intelligence reports against structured data withheld in knowledge bases and specialized databases.
                            </div>
                        </div>
                    </div>
                </li>
                <li className='container'>
                    <div className='row' style={{height:'100%', overflowY: 'scroll'}}>
                        <div className='col s6'>
                            <img alt="Bizgive"  style={{maxHeight:'350px'}}  src={bizgive}/>
                        </div>
                        <div className='col s6' >
                            <div className='grey white-text center'>
                                <h5 style={{margin:'1px',padding:'1px'}}>Developing Artificial Intelligence solutions for societal impact</h5>
                            </div>
                            <div className='white' style={{marginTop:'10px', padding:'5px'}} >
                                <i>Really cool AI projects with <a href="https://bizgive.world"><b>BizGive</b>, London</a>, including:</i>
                            </div>
                            <div className='white'  >
                                <i className=' material-icons' style={{margin: '2px', border: 'solid 1px', verticalAlign: '-6px'}}>view_comfy</i><b>Text classification</b> of company reports and mapping into societal impact categories,
                            </div>
                        </div>
                    </div>
                </li>
                <li className='container'>
                <div className='row' style={{height:'100%', overflowY: 'scroll'}}>
                        <div className='col s6'>
                            {/* <img alt="toulouse" style={{position:'relative', top: '0px', objectFit:'contain', width:'100%', height:'300px'}} src={toulouse}/> */}
                            <img alt="CD2I"  style={{maxHeight:'370px'}}  src={cd2i}/>
                        </div>
                        <div className='col s6' >
                            <div className='grey white-text center'>
                                <h5 style={{margin:'1px',padding:'1px'}}>Developing Artificial Intelligence solutions for the Construction sector</h5>
                            </div>
                            <div className='white' style={{marginTop:'10px', padding:'5px'}} >
                                <i>Really cool AI projects with <a href="https://bizgive.world"><b>CD2I</b>, Toulouse</a>, including:</i>
                            </div>
                            <div className='white'  >
                                <i className=' material-icons' style={{margin: '2px', border: 'solid 1px', verticalAlign: '-6px'}}>view_comfy</i><b>Text classification</b> of submission documents for invitations to tender,
                            </div>
                            <div className='white'  >
                                <i className=' material-icons' style={{margin: '2px', border: 'solid 1px', verticalAlign: '-6px'}}>format_align_left</i><b>Information Extraction</b> of key facts and data from project bid documents,
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
      )
    }
  }
  
  export default Slider;
