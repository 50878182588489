import { FunctionComponent, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PageNotFound from './pages/page-not-found';
import logo from './assets/images/logo.png';
import Sidenav from './components/sidenav';
import TheyTrustUsPage from './pages/theytrustus';
import Whoweare from './pages/whoweare';
import Whatwedo from './pages/whatwedo';
import Demo from './pages/demo';
import vid from './assets/videos/vid.mp4';


const App: FunctionComponent = () => {
  const videoRef = useRef<any>();

  const setPlayBack = () => {
    videoRef.current.playbackRate = 0.3;
  }
  return (
    <Router>

      <div >
        <video ref={videoRef}
          src={vid}
          muted
          onCanPlay={() => setPlayBack()}
          autoPlay={true}
          loop
          style={{ width: '100%' }}
        />
        <div style={{ position: "absolute", top: '0px', background: "rgba(0, 0, 0, 0)", width: '100%', height: '100%' }}>
          <nav className="nav-extended transparent" >
            <div className="nav-wrapper" style={{ minHeight: '100px', margin: '0 auto' }} >
              <a href="#!" className="brand-logo center" >
                <img src={logo} alt='logo' style={{ border: '0.5px solid white', maxHeight: '80px', marginTop: '10px' }} />
              </a>
              <Sidenav toolbarEnabled={true} buttonClass="btn-floating btn-large " classes="fixed-action-btn toolbar " styleDict={{ right: "auto", bottom: "auto" }} links={[]} />
            </div>
            <div className="nav-content" />
          </nav>
          <Switch>
            <Route exact path="/" component={Whatwedo} />
            <Route exact path="/home" component={Whatwedo} />
            <Route exact path="/theytrustus" component={TheyTrustUsPage} />
            <Route exact path="/whoweare" component={Whoweare} />
            <Route exact path="/whatwedo" component={Whatwedo} />
            <Route exact path="/demo" component={Demo} />
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;