import { FunctionComponent, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScrewdriverWrench, faPeopleRoof, faHandshakeSimple } from '@fortawesome/free-solid-svg-icons';
import M from "materialize-css";

const Whatwedo: FunctionComponent = () => {
  const tabsRef = useRef<any|null>();
  useEffect(() => {
    if (tabsRef && tabsRef.current){
        M.Tabs.init(tabsRef.current, {});
    }
},[]);

  return (
    <div className='container border ' style={{ marginTop: '2%' }}>
      <div className='row lighten-3 white-text' style={{ background: "rgba(0, 0, 0, 0.5)" }}  >
        <h3 className='center' style={{margin:'5px'}}>
          <i className="green-text material-icons medium" style={{verticalAlign: '-10px', paddingLeft: '10px', paddingRight: '50px' }}>cloud</i>
          <span>What we do</span>
          </h3>
      </div>
      <div className='row center' style={{ minHeight: '40%', marginBottom: '1px' }}>
        <ul ref={tabsRef} className="tabs transparent tabs-fixed-width">
          <li className="tab col s3 white"><a  href="#development"><FontAwesomeIcon style={{ height: '25px',paddingRight:'2px' }} icon={faHandshakeSimple} />Development</a></li>
          <li className="tab col s3 white"><a  href="#support"><FontAwesomeIcon style={{ height: '25px',paddingRight:'2px'}}  icon={faScrewdriverWrench} />Support</a></li>
          <li className="tab col s3 white"><a  href="#coaching"><FontAwesomeIcon style={{ height: '25px',paddingRight:'2px' }} icon={faPeopleRoof} />Coaching</a></li>
        </ul>
      </div>

      <div className='row white' id="development" style={{ minHeight: '40%', padding: '5px' }}>
          <h5>Proof of Concept</h5>
          <p>You would like to equip your business with AI and you want to start small.
            Say you have a business opportunity or you want to explore the automation of part of a business with Artifical Intelligence.
            You will be looking for a technical interlocutor who can advise and develop demos and proofs of concept. We can help.
          </p>
          <h5>Feature development</h5>
          <p>You are working on an exciting project and your team is going full steam to finish off the features before the deadline.
            Right at this moment new customers get in touch with you and ask for more features that your team cannot handle.
            You are ready to grow your team but this will take months and you need experts on those specific features now. Call us.
          </p>
      </div>
      <div id="support" className="row white" style={{ minHeight: '40%',  padding: '5px' }}>
        <h5>Industrialization</h5>
          <p>You have developped a proof of concept that you are satisfied with and your clients want to use it asap.
            This means putting an application in production, but a million questions arise:
            Is it performant? Are there any bugs? Does it need to be recoded from scratch? How should it be secured? Where should it be deployed?
            Don't stay in doubt and use our expertise to strengthen your projects.
          </p> 
        <h5>Optimization & Scaling</h5>
        <p>
          You have successfully deployed an application running in production, e.g. on a cloud provider platform.
          However it uses up a lot of resources, and customers complain that it is slow or buggy.
          You have just signed contracts with new customers and you are worried that the application will not scale.
          The monthly bill seems to be abnormally high compared to the low number of users or you have experienced a sudden surge in expenses.
          We can help optimize your applications so you focus on growing your business.
        </p>
        <h5>Monitoring & Security</h5>
        <p>
          You have successfully deployed an application running in production, e.g. on a cloud provider platform.
          Then, nothing happens.
          You are wondering if the application you have put so much efforts in productionizing is actually being used and by whom.
          Are there any failures and have they been handled? Has your application been attacked, is it failsafe?
          You need regular, daily feedback.          
        </p>

      </div>
      <div id="coaching" className="row white"  style={{ minHeight: '40%',  padding: '5px' }}>
      <h5>Team/project setup</h5>
        <p>
          You want to set up an AI team or start a new AI project with your existing team but most of them are junior.
          You are looking for a senior profile who can help recruiting, organize the work, coach the team and pilot your projects efficiently.
          We love setting up teams for our customers.
        </p>
        <h5>Target-focussed team coaching</h5>
        <p>
          You have a running AI team and you are looking for ways to improve their well-being, put in place a new process, or reorganize roles within the team for the needs of a new project.
          This is delicate and you don't have the time or the distance to figure out the best solutions.
          Let us help by coaching your team to align with your business goals.
        </p>
      </div>
    </div>
  );
}

export default Whatwedo;