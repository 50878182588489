import { FunctionComponent} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDragon, faFlask, faTruck, faScaleUnbalanced } from '@fortawesome/free-solid-svg-icons';

const Whoweare: FunctionComponent = () => {

  return (
    <div className='container ' style={{ marginTop: '25px' }}>

      <div className='row lighten-3 white-text' style={{ background: "rgba(0, 0, 0, 0.5)" }}  >
        <h3 className='center' style={{margin:'5px'}}>
          <i className="purple-text material-icons medium" style={{verticalAlign: '-10px', paddingLeft: '10px', paddingRight: '50px' }}>cloud</i>
          <span>Who we are</span>
          </h3>
      </div>
      <div className='row'>
        <div className='col grey lighten-3 black-text' >
        <h3><FontAwesomeIcon style={{ paddingRight: '50px' }} icon={faDragon} />Expertise</h3>
          With a unique blend of expertise in Tech Industry and Academia,
          we are uniquely positioned to disseminate, build, and supervise a wide
          range of subjects which arise in integrating AI in your business,
          and provide you with the best advice, tools, and frameworks to grow
          an enduring automated AI function in your organization.
        </div>
      </div>
      <div className='row'>
        <div className='col grey lighten-3 black-text' >
        <h3>
          <FontAwesomeIcon style={{ paddingRight: '50px' }} className='large' icon={faFlask} />Skillset
        </h3>
        We are certified and have expertise in putting AI in production, which requires a large
          range of skills including team coaching, data analysis, Machine learning,
          software engineering, infrastructure building and are constantly honing
          our skills.
      </div>
      </div>
      <div className='row'>
        <div className='col grey lighten-3 black-text' >
        <h3><FontAwesomeIcon style={{ paddingRight: '50px' }} icon={faTruck} />Delivery</h3>
        We limit ourselves to give businesses just what they need and our satisifaction
          is filled when they own their AI capacity. We are only here temporarily and
          we believe it in the interests of both you and us to run short fast delivery iterations.
          If we cannot solve challenges, we will not waste your time and tell you so.
        </div>
      </div>
      <div className='row'>
        <div className='col grey lighten-3 black-text' >
        <h3><FontAwesomeIcon style={{ paddingRight: '50px' }} icon={faScaleUnbalanced} />Ethics</h3>
        We are dedicated to an ethical framework, to improving our environmental impacts,
          to be respectful of our planet. We take the XXI century environmental challenges seriously
          and work towards a world that will be better for our children.
        </div>
      </div>
    </div>
  );
}

export default Whoweare;